import React from 'react';

import { red, blue, orange } from '@material-ui/core/colors';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import withMeta from "./coraWebMComponents/utils/withMeta";
import Snackbar from './coraWebMComponents/feedBack/SnackBar';
import LocalizationProvicer from './coraWebMComponents/utils/withLocalization';
import { RouterProvider, Route, Navigate, Outlet, createHashRouter, createRoutesFromElements } from 'react-router-dom';
import Routes from './routes';
import { connect } from 'react-redux';

import Home from './components/home/Home';
import Login from './components/user/Login';
import ParkingSearch from './components/parking/ParkingSearch';
import DogSearch from './components/dog/DogSearch';
import Dog from './components/dog/Dog';
import PersonSearch from './components/trespass/PersonSearch';
import Person from './components/trespass/Person';
import TrespassPerson from './components/trespass/TrespassPerson';
import TrespassCarSearch from './components/trespass/TrespassCarSearch';
import TrespassCar from './components/trespass/TrespassCar';
import TrespassAdd from './components/trespass/TrespassAdd';
import EvoSearch from './components/evo/EvoSearch';
import Evo from './components/evo/Evo';
import EvoRealtime from './components/evo/EvoRealtime';
import MsgRead from './components/message/MsgRead';
import MsgUnRead from './components/message/MsgUnRead';
import ErrorDialog from './components/errorDialog/ErrorDialog';
import * as userAction from './actions/UserActions';
import MapReport from './components/mapReport/MapReport';

import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/sk';
// import AppBar from './components/appBar/AppBarDrawer';
import HttpsRedirect from './coraWebMComponents/utils/httpsRedirect';
import AppOffline from './coraWebMComponents/views/AppOffline';

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      light: blue[500],
      main: blue[800],
      dark: blue[900],
      contrastText: '#fff',
    },
    secondary: {
      light: orange[900],
      main: orange[800],
      dark: orange[500],
      contrastText: '#fff',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ['sans-serif'].join(','),
    fontSize: 17,
  },
});

const AuthLayout = ({ isAuth }) => {
  if (isAuth) {
    return <Outlet />;
  }
  return <Navigate to={Routes.LOGIN} replace />;
};

const AppRoute = withMeta((props) => {

  const routes = createHashRouter(createRoutesFromElements(
    <Route element={<Outlet />}>
      <Route path={Routes.LOGIN} element={<Login />} />
      <Route element={<AuthLayout isAuth={props.isAuth} />}>
        <Route path={Routes.HOME} element={<Home />} />
        <Route path={Routes.PARKING_SEARCH} element={<ParkingSearch />} />
        <Route path={Routes.TRESPASS_PERSON} element={<TrespassPerson />} />
        <Route path={Routes.PERSON} element={<Person />} />
        <Route path={Routes.PERSON_SEARCH} element={<PersonSearch />} />
        <Route path={Routes.TRESPASS_CAR} element={<TrespassCar />} />
        <Route path={Routes.TRESPASS_CAR_SEARCH} element={<TrespassCarSearch />} />
        <Route path={Routes.TRESPASS_ADD} element={<TrespassAdd />} />
        <Route path={Routes.DOG} element={<Dog />} />
        <Route path={Routes.DOG_SEARCH} element={<DogSearch />} />
        <Route path={Routes.EVO} element={<Evo />} />
        <Route path={Routes.EVO_REALTIME} element={<EvoRealtime />} />
        <Route path={Routes.EVO_SEARCH} element={<EvoSearch />} />
        <Route path={Routes.MSG_READ} element={<MsgRead />} />
        <Route path={Routes.MSG_UNREAD} element={<MsgUnRead />} />
        <Route path={Routes.MAP_REPORT} element={<MapReport />} />
      </Route>
    </Route>
  ), { basename: window.config.basename });

  if (props.meta.isAppOffline) return <AppOffline />

  return (
    <HttpsRedirect>
      <RouterProvider router={routes} />
    </HttpsRedirect>
  );
});

const App = (props) => {

  const handleClose = () => {
    props.dispatch(userAction.hideMsg());
  }

  const handleCloseErrorMsg = () => {
    props.dispatch(userAction.hideErrorMsg());
  }

  if (props.meta.isAppOffline) return <AppOffline />

  return (
    <MuiThemeProvider theme={muiTheme}>
      <LocalizationProvicer locale="sk-SK">
        <MuiPickersUtilsProvider utils={MomentUtils} locale="sk" moment={moment}>
          <div>
            <AppRoute isAuth={props.isAuth} />
            <ErrorDialog />
            <Snackbar
              message={props.msg}
              variant="success"
              onClose={handleClose}
            />
            <Snackbar
              message={props.errorMsg}
              variant="error"
              onClose={handleCloseErrorMsg}
            />
          </div>
        </MuiPickersUtilsProvider>
      </LocalizationProvicer>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuth: state.user.data !== null,
    user: state.user.data,
    msg: state.msg,
    errorMsg: state.errorMsg,
  }
}

export default connect(mapStateToProps)(withMeta(App));
