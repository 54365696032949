import React from 'react';
import PlayCircle from '@material-ui/icons/PlayCircleFilledWhite';
import PropTypes from 'prop-types';

class PlayCircleComponent extends React.Component {
  render() {
    return (
      <PlayCircle
        style={this.props.style}
        className={this.props.className}
      />
    )
  }
}

PlayCircleComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
}

export default PlayCircleComponent;
