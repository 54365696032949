import React from 'react';
import { connect } from 'react-redux';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import Autocomplete from '../../coraWebMComponents/inputs/Autocomplete';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import AppBar from '../appBar/AppBarDrawer';
import InfiniteList from '../../coraWebMComponents/dataDisplay/InfiniteList';
import ListItem from '../../coraWebMComponents/dataDisplay/ListItem';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import * as convert from '../../coraWebMComponents/utils/convert';
import * as validator from '../../coraWebMComponents/utils/validator';
import * as parkingActions from '../../actions/ParkingActions';
import * as mapMarkerActions from '../../actions/MapMarkerActions';
import routes from '../../routes';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import PlayCircleIcon from '../../coraWebMComponents/dataDisplay/icons/PlayCircleIcon';
import PauseCircleIcon from '../../coraWebMComponents/dataDisplay/icons/PauseCircleIcon';
import CancelRoundedIcon from '../../coraWebMComponents/dataDisplay/icons/CancelRoundedIcon';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import * as trespassActions from '../../actions/TrespassActions';
import Info from '../../coraWebMComponents/feedBack/Info';
import List from '../../coraWebMComponents/dataDisplay/List';
import Detail from '../../coraWebMComponents/dataDisplay/Detail';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import FormModal from '../../coraWebMComponents/inputs/FormModal';
import Button from '../../coraWebMComponents/inputs/Button';
import Clear from '../../coraWebMComponents/dataDisplay/icons/ClearIcon';
import withMeta from '../../coraWebMComponents/utils/withMeta';
import { Collections } from '@material-ui/icons';

const _autocompleteCountCharStart = window.config.autocompleteCountCharStart;
const _autocompleteTimeDelay = window.config.autocompleteTimeDelayInMs;
let _intervalTimer = null;
let _isSearching = false;
let _play = true;

class ParkingSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ecv: null,
      errorText: '',
      isAutofillEmpty: false,
      disableTrespassAdd: true,
      isVideoAviable: true,
      videoShow: false,
      iHlasPr: null,
      isLoad: false,
      openParkingOk: false,
      showButtons: false,
      trespassesLoad: false,
      openTrespass: false,
      detailTrespass: null
    };
  }

  componentDidMount = async () => {
    let _ecv = null;
    let _iHlasPr = null;
    const search = window.location.href.split('?').pop();
    const params = new URLSearchParams(search);
    _ecv = params.get("ecv");
    _iHlasPr = parseInt(params.get("iHlasPr"));

    await this.props.dispatch(parkingActions.clearParkingCardsList());
    await this.props.dispatch(parkingActions.clearParkingCards());

    var disTresAdd = true;
    if (this.props.user.Groups.find(x => (x === 1700 || x === 1706)) ? true : false)
      disTresAdd = false;

    if (_ecv) {
      this.setState({ ecv: { Ecv: _ecv, EcvAndSource: _ecv }, iHlasPr: _iHlasPr, disableTrespassAdd: disTresAdd, showButtons: true, });
      await this.handleSelectEcv(null, { Ecv: _ecv, EcvAndSource: _ecv });
    }
    else
      this.setState({ disableTrespassAdd: disTresAdd });

  };

  handleChangeEcv = async (text) => {
    this.setState({ trespassesLoad: false });
    await this.props.dispatch(parkingActions.getParkingCardsList(text, this.props.meta.checkAppOffline));
  };

  handleChangeEcvInput = (text) => {
    let searchText = text.toUpperCase().trim();
    let ecvError = '';
    if (searchText) {
      if (searchText.length > 0)
        ecvError = validator.isValidEcv(searchText);

    }

    if (this.state.ecv) {
      if (searchText.length < this.state.ecv.Ecv.length)
        this.props.dispatch(parkingActions.clearParkingCards());

    }

    this.setState({
      ecv: {
        Ecv: searchText,
        EcvAndSource: searchText
      },
      errorText: ecvError,
      showButtons: false,
    });
  };

  handleSelectEcv = async (name, chosen) => {
    const ecv = chosen ? chosen.Ecv.trim() : '';
    const ecvError = ecv ? validator.isValidEcv(ecv) : '';
    if (!ecvError && ecv) {
      this.setState({ errorText: '', showButtons: true });
      await this.props.dispatch(parkingActions.getParkingCards(ecv, 1, this.props.meta.checkAppOffline));
    }
    else {
      this.setState({ errorText: ecvError, ecv: { Ecv: '', EcvAndSource: '' }, showButtons: false });
      await this.props.dispatch(parkingActions.clearParkingCards());
    }
  };

  handleChangeImage = async (data) => {
    if (!this.state.isVideoAviable || !this.state.videoShow || !_play || _isSearching) {
      return;
    }

    _isSearching = true;
    await this.props.dispatch(parkingActions.getParkingAlpr(data, this.props.meta.checkAppOffline));
    _isSearching = false;

    if (this.props.alpr.Status === 200 && this.props.alpr.Data.length > 0 && _play) {
      const foundEcv = this.props.alpr.Data[0].Characters;
      this.setState({
        ecv: {
          Ecv: foundEcv,
          EcvAndSource: foundEcv
        },
      });
    }
  };

  newTrespass = () => {
    this.props.navigate(routes.TRESPASS_ADD, { state: { ecv: this.state.ecv, I_HLAS_PR: this.state.iHlasPr } });
  };

  componentWillUnmount() {
    if (_intervalTimer) {
      clearInterval(_intervalTimer);
    }
  }

  handleShowVideo = async () => {
    _play = !this.state.videoShow;
    this.setState({ videoShow: _play, errorText: '', ecv: { Ecv: '', EcvAndSource: '' }, showButtons: false });
    await this.props.dispatch(parkingActions.clearParkingCards());

    if (_play)
      this.startRecording();
    else {
      window.location.reload(false);
      // const video = document.querySelector('video');
      // video.pause();
      // video.src = "";

    }
  };

  startRecording = () => {
    const video = document.querySelector('video');
    const canvas = window.canvas = document.querySelector('canvas');

    const constraints = {
      audio: false,
      video: { facingMode: "environment", }

    };

    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      window.stream = stream; // make stream available to browser console
      video.srcObject = stream;

      _intervalTimer = setInterval(async () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);

        const base64Canvas = canvas.toDataURL("image/jpeg").split(';base64,')[1];
        await this.handleChangeImage(base64Canvas);
      }, 1000);

    }).catch((error) => {
      console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
      this.setState({ isVideoAviable: false });
    });

  };

  recordPause = async () => {
    const video = document.querySelector('video');
    if (_play) {
      _play = false;
      video.pause();

      if (this.state.ecv) {
        if (this.state.ecv.Ecv) {
          this.setState({ showButtons: true });
          await this.props.dispatch(parkingActions.getParkingCards(this.state.ecv.Ecv, 1, this.props.meta.checkAppOffline));
        }
      }

    } else {
      this.setState({ errorText: '', ecv: { Ecv: '', EcvAndSource: '' }, showButtons: false });
      await this.props.dispatch(parkingActions.clearParkingCards());
      _play = true;
      video.play();
    }
  };

  handleTrespassCar = async (ecv) => {
    if (this.state.ecv) {
      if (this.state.ecv.Ecv) {
        this.setState({ isLoad: true });
        await this.props.dispatch(trespassActions.getTrespassesByCar(ecv.Ecv, this.props.meta.checkAppOffline));
        this.setState({ trespassesLoad: true, isLoad: false });
      }
    }
  };

  handleOpenTrespass = (id, isProcessed) => {
    const trespass = isProcessed ?
      this.props.trespasses.sprac.find(trespass => trespass.I_PR === id)
      :
      this.props.trespasses.nesprac.find(trespass => trespass.I_PR_MAMP === id);

    const detail = isProcessed ? (
      <div>
        {trespass.D_PR && <div><b>Dňa:</b> {convert.convertDate(trespass.D_PR)}</div>}
        {trespass.OSOBA && <div><b>Meno a priezvisko priestupcu:</b> {trespass.OSOBA}</div>}
        {trespass.N_PRED && <div><b>Porušenie predpisu:</b> {trespass.N_PRED}</div>}
        {trespass.N_U && <div><b>Ulica priestupku:</b> {trespass.N_U}</div>}
        {trespass.OPIS_PR && <div><b>Opis priestupku:</b> {trespass.OPIS_PR}</div>}
        {trespass.RIESENIE && <div><b>Spôsob riešenia:</b> {trespass.RIESENIE}</div>}
      </div>
    ) : (
      <div>
        {trespass.D_PR && <div><b>Dňa:</b> {convert.convertDate(trespass.D_PR)}</div>}
        {trespass.OSOBA && <div><b>Meno a priezvisko:</b> {trespass.OSOBA}</div>}
        {trespass.N_PRED && <div><b>Porušenie predpisu:</b> {trespass.N_PRED}</div>}
        {trespass.N_U && <div><b>Ulica priestupku:</b> {trespass.N_U}</div>}
        {trespass.N_RIES && <div><b>Spôsob riešenia:</b> {trespass.N_RIES}</div>}
        {trespass.ECV && <div><b>EČV:</b> {trespass.ECV}</div>}
        {trespass.SK && <div><b>Výška pokuty:</b> {trespass.SK}</div>}
        {trespass.N_UZ && <div><b>Príslušník MsP:</b> {trespass.N_UZ}</div>}
      </div>
    );

    this.setState({
      detailTrespass: detail,
      openTrespass: true
    });
  };

  handleCloseTrespass = async () => {
    await this.setState({
      openTrespass: false
    });
  };

  handleOpenParkingOk = () => {
    this.setState({ openParkingOk: true });
  };

  handleCloseParkingOk = () => {
    this.setState({ openParkingOk: false });
  };

  setParkingOk = async () => {
    if (this.state.iHlasPr) {
      this.setState({ isLoad: true, openParkingOk: false });
      await this.props.dispatch(mapMarkerActions.setMapMarker(this.state.iHlasPr, 3, this.props.meta.checkAppOffline));
    }
    this.props.navigate(routes.MAP_REPORT);
  };

  openPhotos = async (e, id, isProcessed) => {
    e.stopPropagation();
    await this.props.dispatch(trespassActions.getTrespassPhotos(id, isProcessed, this.props.meta.checkAppOffline));
    this.setState({ openFoto: true });
  };

  handleFotoClose = () => {
    this.setState({ openFoto: false });
  };

  openFotoDetail = (foto) => {
    this.setState({ openFotoDetail: true, foto });
  };

  closeFotoDetail = () => {
    this.setState({ openFotoDetail: false });
  };

  render() {
    const fotoList = this.props.trespassPhotos.map(m => {
      return (<div key={m.I_ZAZ_ZAZ} >
        {
          m.HODN_DAT ? (
            <img
              style={{ border: '0px solid #ddd', borderRadius: '10px', padding: '7px', width: '350px' }}
              src={`data:image/png;base64, ${m.HODN_DAT}`}
              onClick={() => this.openFotoDetail(m.HODN_DAT)}
            />
          ) : (
            <Typography variant="caption" style={{ marginBottom: '10px', textAlign: "center" }} >Fotografia neexistuje</Typography>
          )
        }
      </div >);
    });

    const fotoFrm =
      <div>
        <div className="row">
          <Typography variant="subtitle1" style={{ marginLeft: '10px', alignSelf: 'center' }}>Fotografie</Typography>
          <Button
            variant="text"
            color="primary"
            size="large"
            onClick={() => this.handleFotoClose()}
          >
            <Clear />
          </Button>
        </div>
        {this.props.trespassPhotos.length > 0 && fotoList}
        {this.props.trespassPhotos.length === 0 &&
          <Typography variant="caption" style={{ margin: '30px 30px 30px 30px', textAlign: "center" }} >Fotografie neexistujú!</Typography>
        }
      </div>;

    const fotoDetail =
      <div >
        <img
          width="100%"
          height="auto"
          max-width="600%"
          min-width="10%"
          src={`data:image/png;base64, ${this.state.foto}`}
          onClick={() => this.closeFotoDetail()}
        />
      </div>;

    return (
      <div>
        <AppBar title='Parkovanie' />
        <div className='container'>
          <Paper className='content' elevation={1} >
            <React.Fragment>

              {this.state.iHlasPr &&
                <div className='row' style={{ marginBottom: '12px' }}>
                  <IconButton
                    label=""
                    onClick={() => this.props.navigate(routes.MAP_REPORT)}
                    toolTip="späť na mapu"
                    toolTipPlace="bottom"
                    style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0, paddingBottom: 0 }}
                  >
                    <ArrowBack style={{ fontSize: 30 }} />
                  </IconButton>
                  <Typography variant="subtitle2" style={{ fontWeight: 'bold', color: 'black', textAlign: 'center', alignSelf: 'center', marginRight: '30px' }}>
                    {'Kontrola úhrady za parkovanie'}
                  </Typography>
                  <div></div>
                </div>
              }

              {!this.state.iHlasPr && <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: 'black', textAlign: 'center' }}>{'Kontrola úhrady za parkovanie'}</Typography>}
              <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 10 }}>
                <Autocomplete
                  disabled={this.state.videoShow}
                  data={this.props.parkingCardsList}
                  total={this.props.total}
                  isLoading={this.props.isLoading}
                  schema={{ label: 'EcvAndSource', value: 'Ecv' }}
                  onChange={this.handleChangeEcv}
                  onChangeInput={this.handleChangeEcvInput}
                  onSelect={this.handleSelectEcv}
                  selectedItem={this.state.ecv ? this.state.ecv : {}}
                  label="EČV vozidla"
                  errorText={this.state.errorText}
                  name='ecv'
                  timeDelay={_autocompleteTimeDelay}
                  countCharStart={_autocompleteCountCharStart}
                />
                <CircularProgress loading={this.props.isLoad || this.state.isLoad} />
                {!this.state.videoShow && this.state.isVideoAviable &&
                  <IconButton
                    label=""
                    onClick={() => this.handleShowVideo()}
                    toolTip="Zapnúť kameru"
                    toolTipPlace="bottom"
                    style={{ paddingLeft: 5, paddingTop: 0, paddingRight: 0, paddingBottom: 0 }}
                  >
                    <PlayCircleIcon style={{ width: 40, height: 40, padding: 0 }} />
                  </IconButton>
                }
              </div>
              <center>
                <div style={{ position: 'relative' }}>
                  <video playsInline autoPlay style={{ marginBottom: '10px', width: '100%', zIndex: '10', display: (this.state.isVideoAviable && this.state.videoShow) ? 'block' : 'none' }}></video>
                  {this.state.isVideoAviable && this.state.videoShow &&
                    <div >
                      <IconButton
                        label=""
                        onClick={() => this.handleShowVideo()}
                        toolTip="Vypnúť kameru"
                        toolTipPlace="bottom"
                        style={{ zIndex: '11', position: 'absolute', top: '0px', right: '0px', width: '25%', height: '30%' }}
                      >
                        <CancelRoundedIcon style={{ width: '60%', height: '60%', padding: '1%' }} />
                      </IconButton>

                      {!_play &&
                        <IconButton
                          label=""
                          onClick={this.recordPause}
                          toolTip="Play"
                          toolTipPlace="bottom"
                          style={{ zIndex: '11', position: 'absolute', top: '25%', left: '30%', width: '40%', height: '50%' }}
                        >
                          <PlayCircleIcon style={{ width: '50%', height: '50%', padding: '10%' }} />
                        </IconButton>
                      }
                      {_play &&
                        <IconButton
                          label=""
                          onClick={this.recordPause}
                          toolTip="Pause"
                          toolTipPlace="bottom"
                          style={{ zIndex: '11', position: 'absolute', top: '25%', left: '30%', width: '40%', height: '50%' }}
                        >
                          <PauseCircleIcon style={{ width: '50%', height: '50%', padding: '10%' }} />
                        </IconButton>
                      }
                    </div>
                  }
                </div>
                <canvas width="800" height="60" style={{ display: 'none' }}></canvas>
              </center>
              <InfiniteList
                loading={this.props.isLoad}
                onEnd={() => { }}
              >
                {this.props.parkingCards.filter((x) => x.ECV_NADR !== "SMS_TICKET").map((parkingCard) =>
                  <ItemCard key={parkingCard.I_KARTA} data={parkingCard} />
                )}
                {this.props.parkingCards.filter((x) => x.ECV_NADR === "SMS_TICKET").map((parkingCard) =>
                  <ItemSms key={parkingCard.I_KARTA} data={parkingCard} />
                )}
              </InfiniteList>
              {(this.state.showButtons
                && (!this.state.isVideoAviable || !this.state.videoShow || (this.state.isVideoAviable && this.state.videoShow && !_play)))
                && !this.props.isLoading
                && !this.props.isLoad
                && !this.props.parkingCards.find((x) => x.I_KARTA < 0)
                &&
                <div >
                  {this.props.parkingCards.length === 0 &&
                    <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                      {'Pre vozidlo sa nenašla úhrada za parkovanie!'}
                    </Typography>
                  }
                  {this.props.parkingCards.length > 0 && this.state.iHlasPr &&
                    <RaisedButton fullwidth={true} onClick={() => this.handleOpenParkingOk()} style={{ backgroundColor: '#4caf50' }}>
                      {'Platné parkovanie'}
                    </RaisedButton>
                  }
                  <RaisedButton fullwidth={true} onClick={() => this.newTrespass()} disabled={this.state.disableTrespassAdd}>
                    {'Zaevidovať priestupok'}
                  </RaisedButton>
                </div>
              }
            </React.Fragment>
          </Paper>
          {(this.state.showButtons
            && (!this.state.isVideoAviable || !this.state.videoShow || (this.state.isVideoAviable && this.state.videoShow && !_play)))
            && !this.props.isLoading
            && !this.props.isLoad
            &&
            <div>
              <Paper className='content' elevation={1} >
                <React.Fragment>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: 'black', textAlign: 'center' }}>
                    {'Kontrola priestupkov vozidla'}
                  </Typography>
                  <RaisedButton fullwidth={true} onClick={() => this.handleTrespassCar(this.state.ecv)} style={{ backgroundColor: '#7BCBDF' }} >
                    {'Vyhľadať vozidlo'}
                  </RaisedButton>
                </React.Fragment>
              </Paper>
              {this.state.trespassesLoad &&
                <>
                  <Paper className='content' elevation={1}>
                    <React.Fragment>
                      <h3>EČV {this.state.ecv.Ecv}, priestupkov: {this.props.trespasses.sprac.length}</h3>
                      {this.props.trespasses.sprac.length === 0 &&
                        <div>
                          <Info text='Vozidlo nemá evidovaný žiaden priestupok.' />
                        </div>
                      }
                      {this.props.trespasses.sprac.length > 0 &&
                        <List>
                          {this.props.trespasses.sprac.map((trespass) =>
                            <ListItem key={trespass.I_PR} onClick={() => this.handleOpenTrespass(trespass.I_PR, true)}>
                              <div className="item" style={{ width: "100%" }}>
                                {trespass.L_FOTO &&
                                  <IconButton
                                    disableTooltip={true}
                                    style={{ float: 'right' }}
                                    onClick={(e) => this.openPhotos(e, trespass.I_PR_ORIG, true)}>
                                    <Collections></Collections>
                                  </IconButton>
                                }
                                {trespass.D_PR && <div><b>Dňa:</b> {convert.convertDate(trespass.D_PR)}</div>}
                                {trespass.OSOBA && <div><b>Meno a priezvisko priestupcu:</b> {trespass.OSOBA}</div>}
                                {trespass.N_PRED && <div><b>Porušenie predpisu:</b> {trespass.N_PRED}</div>}
                                {trespass.N_U && <div><b>Ulica priestupku:</b> {trespass.N_U}</div>}
                                {trespass.RIESENIE && <div><b>Spôsob riešenia:</b> {trespass.RIESENIE}</div>}
                              </div>
                            </ListItem>
                          )}
                        </List>}
                    </React.Fragment>
                  </Paper>
                  <Paper className='content' elevation={1} style={{ backgroundColor: '#bbdbee' }} >
                    <React.Fragment>
                      <h3>EČV {this.state.ecv.Ecv}, nespracovaných priestupkov: {this.props.trespasses.nesprac.length}</h3>
                      {this.props.trespasses.nesprac.length === 0 &&
                        <div>
                          <Info text='Vozidlo nemá evidovaný žiaden priestupok.' />
                        </div>
                      }
                      {this.props.trespasses.nesprac.length > 0 &&
                        <List>
                          {this.props.trespasses.nesprac.map((trespass) =>
                            <ListItem key={trespass.I_PR_MAMP} onClick={() => this.handleOpenTrespass(trespass.I_PR_MAMP, false)}>
                              <div className="item" style={{ width: "100%" }}>
                                {trespass.L_FOTO &&
                                  <IconButton
                                    disableTooltip={true}
                                    style={{ float: 'right' }}
                                    onClick={(e) => this.openPhotos(e, trespass.I_PR_MAMP, false)}>
                                    <Collections></Collections>
                                  </IconButton>
                                }
                                {trespass.D_PR && <div><b>Dňa:</b> {convert.convertDate(trespass.D_PR)}</div>}
                                {trespass.OSOBA && <div><b>Meno a priezvisko:</b> {trespass.OSOBA}</div>}
                                {trespass.N_PRED && <div><b>Porušenie predpisu:</b> {trespass.N_PRED}</div>}
                                {trespass.N_U && <div><b>Ulica priestupku:</b> {trespass.N_U}</div>}
                                {trespass.N_RIES && <div><b>Spôsob riešenia:</b> {trespass.N_RIES}</div>}
                                {trespass.ECV && <div><b>EČV:</b> {trespass.ECV}</div>}
                                {trespass.SK && <div><b>Výška pokuty:</b> {trespass.SK}</div>}
                                {trespass.N_UZ && <div><b>Príslušník MsP:</b> {trespass.N_UZ}</div>}
                              </div>
                            </ListItem>
                          )}
                        </List>}
                    </React.Fragment>
                  </Paper>
                  <Detail
                    open={this.state.openTrespass}
                    detail={this.state.detailTrespass}
                    handleClose={this.handleCloseTrespass}
                  />
                </>
              }
            </div>
          }
        </div>
        <Dialog
          open={this.state.openParkingOk}
          onClose={this.handleCloseParkingOk}
          contentText='Vozidlo má úhradu za parkovanie.<br> Nastaviť stav hlásenia na vyriešené?'
          title='Platné parkovanie'
          actions={[
            {
              label: 'Nie',
              color: 'primary',
              onClick: this.handleCloseParkingOk,
              keyboardFocused: false
            },
            {
              label: 'Áno',
              color: 'primary',
              onClick: this.setParkingOk,
              keyboardFocused: true
            }
          ]}
        />
        <FormModal
          disableMargins={true}
          open={this.state.openFoto}
          onClose={this.handleFotoClose}
          elements={fotoFrm}
        />
        <FormModal
          fullScreen={true}
          open={this.state.openFotoDetail}
          onClose={this.closeFotoDetail}
          elements={fotoDetail}
        />
      </div >
    );
  }
}

class ItemCard extends React.Component {
  render() {
    return (
      <ListItem>
        <div className="item">
          {this.props.data.I_KARTA.toString().substr(0, 1) === "1" &&
            <Typography variant="subtitle1" color='primary'>{'Karta'}</Typography>
          }
          {this.props.data.I_KARTA.toString().substr(0, 1) === "2" &&
            <Typography variant="subtitle1" color='primary'>{'Kredit'}</Typography>
          }
          {this.props.data.I_KARTA.toString().substr(0, 1) === "3" &&
            <Typography variant="subtitle1" color='primary'>{'Lístok'}</Typography>
          }
          {this.props.data.I_KARTA < 0 &&
            <Typography variant="subtitle1" color='error'>{'Nepodarilo sa overenie parkovného!'}</Typography>
          }
          {this.props.data.I_KARTA > 0 &&
            <div>
              <div><b>EČV:</b> {this.props.data.ECV}</div>
              <div><b>Číslo PK:</b> {this.props.data.C_KARTA}</div>
              <div><b>Držiteľ:</b> {this.props.data.DRZITEL}</div>
              <div><b>Ulica TP držiteľa:</b> {this.props.data.N_U}</div>
              <div><b>Dátum platnosti od:</b> {convert.convertDate(this.props.data.D_OD)}</div>
              <div><b>Dátum platnosti do:</b> {convert.convertDate(this.props.data.D_DO)}</div>
              <div><b>Adresa PK:</b> {this.props.data.ADRESA}</div>
              <div><b>Zóna:</b> {this.props.data.N_ZONA}</div>
              <div><b>Rozšírená platnosť v zónach:</b> {this.props.data.N_ZONA_RPL}</div>
              <div><b>Kategória PK:</b> {this.props.data.N_KARTA_KAT}</div>
              <div><b>Typ PK:</b> {this.props.data.N_KARTA_TYP2}</div>
              <div><b>Miesto platnosti:</b> {this.props.data.MIESTO}</div>
            </div>
          }
        </div>
      </ListItem>
    );
  }
}

class ItemSms extends React.Component {
  render() {
    return (
      <ListItem>
        <div className="item">
          {this.props.data.I_KARTA > 0 &&
            <div>
              {this.props.data.I_KARTA.toString().substr(0, 1) !== "4" &&
                <Typography variant="subtitle1" color='primary'>{'SMS'}</Typography>
              }
              {this.props.data.I_KARTA.toString().substr(0, 1) === "4" &&
                <Typography variant="subtitle1" color='primary'>{'Parkomat'}</Typography>
              }
            </div>
          }
          {this.props.data.I_KARTA < 0 &&
            <Typography variant="subtitle1" color='error'>{'Nepodarilo sa overenie parkovného!'}</Typography>
          }
          {this.props.data.I_KARTA > 0 &&
            <div>
              <div><b>EČV:</b> {this.props.data.ECV}</div>
              <div><b>Zóna:</b> {this.props.data.N_ZONA}</div>
              <div><b>Od:</b> {convert.convertDateTime(this.props.data.D_OD)}</div>
              <div><b>Do:</b> {convert.convertDateTime(this.props.data.D_DO)}</div>
              {this.props.data.ADRESA && <div><b>Parkovisko:</b> {this.props.data.ADRESA}</div>}
              {this.props.data.N_KARTA_KAT && <div><b>Tarify:</b> {this.props.data.N_KARTA_KAT}</div>}
            </div>}
        </div >
      </ListItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parkingCardsList: state.parkingCardsList.data,
    isLoading: state.parkingCardsList.isLoading,
    total: state.parkingCardsList.total,
    alpr: state.alpr,
    parkingCards: state.parkingCards.data,
    isLoad: state.parkingCards.isLoading,
    page: state.parkingCards.page,
    hasMore: state.parkingCards.hasMore,
    user: state.user.data,
    trespasses: state.trespassesByCar.data,
    trespassPhotos: state.trespassPhotos.data,
  };
};

export default withRouter(withMeta(connect(mapStateToProps)(ParkingSearch)));