import React from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from 'material-ui-pickers';
import { withLocalizationConsumer } from '../../coraWebMComponents/utils/withLocalization';

class TimePickerComponent extends React.Component {
  onChange = (mom) => {
    const cas = mom !== null ? mom.toDate() : null;
    this.props.onChange(this.props.name, cas);
  }

  render() {
    return (
      <TimePicker
        disableOpenOnEnter
        label={this.props.label}
        clearable={this.props.clearable}
        ampm={false}
        value={this.props.value}
        onChange={this.onChange}
        style={this.props.style}
        clearLabel={this.props.translate('timepicker.clear')}
        cancelLabel={this.props.translate('timepicker.cancel')}
        okLabel={this.props.translate('timepicker.set')}
        opento="hours"
        fullWidth={this.props.fullWidth}
        className={this.props.className}
        minutesStep={this.props.minutesStep}
        disabled={this.props.disabled}
        autoOk={this.props.autoOk}
      />
    )
  }
}

TimePickerComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  clearable: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  minutesStep: PropTypes.number,
  disabled: PropTypes.bool,
  autoOk: PropTypes.bool,
}

export default withLocalizationConsumer(TimePickerComponent);
