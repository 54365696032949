import React from 'react';
import NavigationClose from '@material-ui/icons/NavigateBefore';

class NavigationCloseComponent extends React.Component {
  render() {
    return (
      <NavigationClose />
    )
  }
}

export default NavigationCloseComponent;