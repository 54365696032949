import React from 'react';
import ListItem from '../../coraWebMComponents/dataDisplay/ListItem';
import List from '../../coraWebMComponents/dataDisplay/List';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import AppBar from '../appBar/AppBar';
import Info from '../../coraWebMComponents/feedBack/Info';
import LinearProgress from '../../coraWebMComponents/feedBack/progress/LinearProgress';
import Detail from '../../coraWebMComponents/dataDisplay/Detail';

import { connect } from 'react-redux';
import routes from '../../routes';
import * as convert from '../../coraWebMComponents/utils/convert';
import * as trespassActions from '../../actions/TrespassActions';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import FormModal from '../../coraWebMComponents/inputs/FormModal';
import Button from '../../coraWebMComponents/inputs/Button';
import { Collections } from '@material-ui/icons';
import Clear from '../../coraWebMComponents/dataDisplay/icons/ClearIcon';
import withMeta from '../../coraWebMComponents/utils/withMeta';

class TrespassCar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ecv: '',
      open: false,
      detail: ''
    };
  }

  handleOpen = (id, isProcessed) => {
    const trespass = isProcessed ?
      this.props.trespasses.sprac.find(trespass => trespass.I_PR === id)
      :
      this.props.trespasses.nesprac.find(trespass => trespass.I_PR_MAMP === id)

    const detail = isProcessed ? (
      <div>
        {trespass.D_PR && <div><b>Dňa:</b> {convert.convertDate(trespass.D_PR)}</div>}
        {trespass.OSOBA && <div><b>Meno a priezvisko priestupcu:</b> {trespass.OSOBA}</div>}
        {trespass.N_PRED && <div><b>Porušenie predpisu:</b> {trespass.N_PRED}</div>}
        {trespass.N_U && <div><b>Ulica priestupku:</b> {trespass.N_U}</div>}
        {trespass.OPIS_PR && <div><b>Opis priestupku:</b> {trespass.OPIS_PR}</div>}
        {trespass.RIESENIE && <div><b>Spôsob riešenia:</b> {trespass.RIESENIE}</div>}
      </div>
    ) : (
      <div>
        {trespass.D_PR && <div><b>Dňa:</b> {convert.convertDate(trespass.D_PR)}</div>}
        {trespass.OSOBA && <div><b>Meno a priezvisko:</b> {trespass.OSOBA}</div>}
        {trespass.N_PRED && <div><b>Porušenie predpisu:</b> {trespass.N_PRED}</div>}
        {trespass.N_U && <div><b>Ulica priestupku:</b> {trespass.N_U}</div>}
        {trespass.N_RIES && <div><b>Spôsob riešenia:</b> {trespass.N_RIES}</div>}
        {trespass.ECV && <div><b>EČV:</b> {trespass.ECV}</div>}
        {trespass.SK && <div><b>Výška pokuty:</b> {trespass.SK}</div>}
        {trespass.N_UZ && <div><b>Príslušník MsP:</b> {trespass.N_UZ}</div>}
      </div>
    );

    this.setState({
      detail: detail,
      open: true
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  openPhotos = async (e, id, isProcessed) => {
    e.stopPropagation();
    await this.props.dispatch(trespassActions.getTrespassPhotos(id, isProcessed, this.props.meta.checkAppOffline))
    this.setState({ openFoto: true })
  }

  handleFotoClose = () => {
    this.setState({ openFoto: false })
  }

  openFotoDetail = (foto) => {
    this.setState({ openFotoDetail: true, foto });
  };

  closeFotoDetail = () => {
    this.setState({ openFotoDetail: false });
  };

  render() {
    const fotoList = this.props.trespassPhotos.map(m => {
      return (<div key={m.I_ZAZ_ZAZ} >
        {
          m.HODN_DAT ? (
            <img
              style={{ border: '0px solid #ddd', borderRadius: '10px', padding: '7px', width: '350px' }}
              src={`data:image/png;base64, ${m.HODN_DAT}`}
              onClick={() => this.openFotoDetail(m.HODN_DAT)}
            />
          ) : (
            <Typography variant="caption" style={{ marginBottom: '10px', textAlign: "center" }} >Fotografia neexistuje</Typography>
          )
        }
      </div >)
    })

    const fotoFrm =
      <div>
        <div className="row">
          <Typography variant="subtitle1" style={{ marginLeft: '10px', alignSelf: 'center' }}>Fotografie</Typography>
          <Button
            variant="text"
            color="primary"
            size="large"
            onClick={() => this.handleFotoClose()}
          >
            <Clear />
          </Button>
        </div>
        {this.props.trespassPhotos.length > 0 && fotoList}
        {this.props.trespassPhotos.length === 0 &&
          <Typography variant="caption" style={{ margin: '30px 30px 30px 30px', textAlign: "center" }} >Fotografie neexistujú!</Typography>
        }
      </div>

    const fotoDetail =
      <div >
        <img
          width="100%"
          height="auto"
          max-width="600%"
          min-width="10%"
          src={`data:image/png;base64, ${this.state.foto}`}
          onClick={() => this.closeFotoDetail()}
        />
      </div>

    const length = this.props.trespasses.sprac.length;
    const unprocessedLength = this.props.trespasses.nesprac.length;
    return (
      <div>
        <AppBar title='Vozidlo v priestupkoch' closable />
        <LinearProgress loading={this.props.isLoading} />
        <div className='container'>
          <Paper className='content' elevation={1} >
            <React.Fragment>
              <h3>EČV {this.state.ecv}, priestupkov: {length}</h3>
              {(length === 0 && !this.props.isLoading) &&
                <Info text='Vozidlo nemá evidovaný žiaden priestupok.' />
              }
              {(length > 0 && !this.props.isLoading) &&
                <List>
                  {this.props.trespasses.sprac.map((trespass) =>
                    <Item openPhotos={this.openPhotos} key={trespass.I_PR} data={trespass} handleOpen={this.handleOpen} />
                  )}
                </List>
              }
            </React.Fragment>
          </Paper>
          <Paper className='content' elevation={1} style={{ backgroundColor: '#bbdbee' }} >
            <React.Fragment>
              <h3>EČV {this.state.ecv}, nespracovaných priestupkov: {unprocessedLength}</h3>
              {(unprocessedLength === 0 && !this.props.isLoading) &&
                <Info text='Vozidlo nemá evidovaný žiaden nespracovaný priestupok.' />
              }
              {(unprocessedLength > 0 && !this.props.isLoading) &&
                <List>
                  {this.props.trespasses.nesprac.map((trespass) =>
                    <UnprocessedItem openPhotos={this.openPhotos} key={trespass.I_PR_MAMP} data={trespass} handleOpen={this.handleOpen} />
                  )}
                </List>
              }
            </React.Fragment>
          </Paper>
        </div>
        {
          ((length > 0 || unprocessedLength > 0) && !this.props.isLoading) &&
          <Detail
            open={this.state.open}
            detail={this.state.detail}
            handleClose={this.handleClose} />
        }
        <FormModal
          disableMargins={true}
          open={this.state.openFoto}
          onClose={this.handleFotoClose}
          elements={fotoFrm}
        />
        <FormModal
          fullScreen={true}
          open={this.state.openFotoDetail}
          onClose={this.closeFotoDetail}
          elements={fotoDetail}
        />
      </div >
    );
  }

  componentDidMount() {
    if (this.props.location.state) {
      const ecv = this.props.location.state.ecv;
      this.props.dispatch(trespassActions.getTrespassesByCar(ecv, this.props.meta.checkAppOffline));
      this.setState({ ecv: ecv });
    }
    else {
      this.props.navigate(routes.TRESPASS_CAR_SEARCH);
    }
  }
}

class Item extends React.Component {
  render() {
    return (
      <ListItem onClick={(event) => this.props.handleOpen(this.props.data.I_PR, true)}>
        <div className="item" style={{ width: "100%" }}>
          {this.props.data.L_FOTO &&
            <IconButton
              style={{ float: 'right' }}
              disableTooltip={true}
              onClick={(e) => this.props.openPhotos(e, this.props.data.I_PR_ORIG, true)}>
              <Collections></Collections>
            </IconButton>
          }
          {this.props.data.D_PR && <div><b>Dňa:</b> {convert.convertDate(this.props.data.D_PR)}</div>}
          {this.props.data.OSOBA && <div><b>Meno a priezvisko priestupcu:</b> {this.props.data.OSOBA}</div>}
          {this.props.data.N_PRED && <div><b>Porušenie predpisu:</b> {this.props.data.N_PRED}</div>}
          {this.props.data.N_U && <div><b>Ulica priestupku:</b> {this.props.data.N_U}</div>}
          {this.props.data.RIESENIE && <div><b>Spôsob riešenia:</b> {this.props.data.RIESENIE}</div>}
        </div>
      </ListItem >
    );
  }
}

class UnprocessedItem extends React.Component {
  render() {
    return (
      <ListItem onClick={(event) => this.props.handleOpen(this.props.data.I_PR_MAMP, false)}>
        <div className="item" style={{ width: "100%" }}>
          {this.props.data.L_FOTO &&
            <IconButton
              style={{ float: 'right' }}
              disableTooltip={true}
              onClick={(e) => this.props.openPhotos(e, this.props.data.I_PR_MAMP, false)}>
              <Collections></Collections>
            </IconButton>
          }

          {this.props.data.D_PR && <div><b>Dňa:</b> {convert.convertDate(this.props.data.D_PR)}</div>}
          {this.props.data.OSOBA && <div><b>Meno a priezvisko:</b> {this.props.data.OSOBA}</div>}
          {this.props.data.N_PRED && <div><b>Porušenie predpisu:</b> {this.props.data.N_PRED}</div>}
          {this.props.data.N_U && <div><b>Ulica priestupku:</b> {this.props.data.N_U}</div>}
          {this.props.data.N_RIES && <div><b>Spôsob riešenia:</b> {this.props.data.N_RIES}</div>}
          {this.props.data.ECV && <div><b>EČV:</b> {this.props.data.ECV}</div>}
          {this.props.data.SK && <div><b>Výška pokuty:</b> {this.props.data.SK}</div>}
          {this.props.data.N_UZ && <div><b>Príslušník MsP:</b> {this.props.data.N_UZ}</div>}
        </div>
      </ListItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trespasses: state.trespassesByCar.data,
    isLoading: state.trespassesByCar.isLoading,
    trespassPhotos: state.trespassPhotos.data,
  }
}

export default withRouter(withMeta(connect(mapStateToProps)(TrespassCar)));