import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../coraWebMComponents/inputs/TextField';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import AttachmentInput from '../../coraWebMComponents/inputs/AttachmentInput';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import AppBar from '../appBar/AppBarDrawer';
import routes from '../../routes';
import * as validator from '../../coraWebMComponents/utils/validator';
import * as parkingActions from '../../actions/ParkingActions';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import withMeta from '../../coraWebMComponents/utils/withMeta';

class TrespassCarSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ecv: '',
      errorText: '',
    };
  }

  handleChangeEcv = (name, value) => {
    this.setState({
      ecv: value.toUpperCase()
    });
  }

  handleSearch = () => {
    const ecv = this.state.ecv.trim();
    const ecvError = validator.isValidEcv(ecv);

    if (!ecvError.length) {
      this.setState({ errorText: '' });
      this.props.navigate(routes.TRESPASS_CAR, { state: this.state });
    }
    else {
      this.setState({ errorText: ecvError });
    }
  }

  handleChangeImage = async (image) => {
    await this.props.dispatch(parkingActions.getParkingAlpr(image.data, this.props.meta.checkAppOffline));
    if (this.props.alpr.Status === 200 && this.props.alpr.Data.length > 0) {
      this.setState({
        ecv: this.props.alpr.Data[0].Characters
      });
    } else this.setState({ ecv: '' });
  }

  render() {
    return (
      <div>
        <AppBar title='Vozidlo v priestupkoch' />
        <div className='container'>
          <Paper className='content' elevation={1} >
            <React.Fragment>
              <h3>Kontrola priestupkov vozidla</h3>
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <TextField
                  label="EČV vozidla"
                  onChange={this.handleChangeEcv}
                  fullwidth={true}
                  value={this.state.ecv}
                  errorText={this.state.errorText}
                  name='ecv'
                />
                <CircularProgress loading={this.props.alpr.IsLoading} />
                <AttachmentInput
                  disabled={false}
                  onChange={this.handleChangeImage}
                  iconType={1}
                />
              </div>
              <RaisedButton
                fullwidth={true}
                onClick={this.handleSearch}
              >
                {'Vyhľadať vozidlo'}
              </RaisedButton>
            </React.Fragment>
          </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alpr: state.alpr
  }
}

export default withRouter(withMeta(connect(mapStateToProps)(TrespassCarSearch)));