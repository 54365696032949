import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../coraWebMComponents/inputs/TextField';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import AppBar from '../appBar/AppBarDrawer';
import Error from '../../coraWebMComponents/feedBack/Error';
//import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
//import AttachmentInput from '../../coraWebMComponents/inputs/AttachmentInput';

import routes from '../../routes';
import * as validator from '../../coraWebMComponents/utils/validator';
import * as parkingActions from '../../actions/ParkingActions';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import withMeta from '../../coraWebMComponents/utils/withMeta';

let _intervalTimer = null;

class EvoSearchRealtime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confidence: 0.0,
      ecv: '',
      vin: '',
      reason: '',
      errorTextReason: '',
      errorText: '',
      isVideoAviable: true
    };
  }

  componentWillUnmount() {
    if (_intervalTimer) {
      clearInterval(_intervalTimer);
    }
  }

  componentDidMount() {
    const video = document.querySelector('video');
    const canvas = window.canvas = document.querySelector('canvas');

    const constraints = {
      audio: false,
      video: true
    };

    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      window.stream = stream; // make stream available to browser console
      video.srcObject = stream;

      _intervalTimer = setInterval(() => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);

        const base64Canvas = canvas.toDataURL("image/jpeg").split(';base64,')[1];
        this.handleChangeImage(base64Canvas);
      }, 1000);

    }).catch((error) => {
      console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
      this.setState({ isVideoAviable: false });
    });
  }

  handleSearch = () => {
    const reason = this.state.reason;
    const ecv = this.state.ecv.trim();
    const ecvError = validator.isValidEcv(ecv);
    const vin = this.state.vin;

    const errorText = (ecvError.length === 0 || vin) ? '' : 'Je nutné zadať EČV alebo VIN. ' + ecvError;
    const errorTextReason = reason ? '' : 'Je nutné zadať dôvod.';
    this.setState({ errorText, errorTextReason });

    if (reason && !errorText) {
      this.props.navigate(routes.EVO, { state: this.state });
    }
  }

  handleChangeEcv = (name, value) => {
    this.setState({
      ecv: value.toUpperCase()
    });
  }

  handleChangeVin = (name, value) => {
    this.setState({
      vin: value.toUpperCase()
    });
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  }

  handleChangeImage = async (data) => {
    await this.props.dispatch(parkingActions.getParkingAlpr(data, this.props.meta.checkAppOffline));
    if (this.props.alpr.Status === 200 && this.props.alpr.Data.length > 0) {

      this.setState({
        ecv: this.props.alpr.Data[0].Characters,
        confidence: this.props.alpr.Data[0].OverallConfidence
      });
    }
  }

  render() {
    return (
      <div>
        <AppBar title='EVO' />
        <div className='container'>
          <Paper className='content' elevation={1} >
            <h3>Kontrola záznamov o vozidle v reálnom čase</h3>
            {this.state.isVideoAviable &&
              <center>
                <video playsInline autoPlay style={{ marginBottom: '10px', width: '90%' }}></video>
                <canvas width="800" height="600" style={{ display: 'none' }}></canvas>
              </center>}
            <div className="row">
              <div style={{ display: 'flex', alignItems: 'flex-end', width: '49%' }}>
                <TextField
                  style={{ width: '100%' }}
                  label="EČV vozidla"
                  onChange={this.handleChangeEcv}
                  value={this.state.ecv}
                  name='ecv'
                />
              </div>
              <TextField
                style={{ width: '49%' }}
                label="VIN"
                onChange={this.handleChangeVin}
                value={this.state.vin}
                name='vin'
              />
            </div>
            <Error text={this.state.errorText} />
            <TextField
              label="Dôvod"
              errorText={this.state.errorTextReason}
              error={this.state.errorTextReason ? true : false}
              onChange={this.handleChange}
              value={this.state.reason}
              fullwidth={true}
              name='reason'
            />
            <RaisedButton
              fullwidth={true}
              onClick={this.handleSearch}
            >
              {'Vyhľadať'}
            </RaisedButton>
          </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alpr: state.alpr
  }
}

export default withRouter(withMeta(connect(mapStateToProps)(EvoSearchRealtime)));