import React from 'react';
import PauseCircle from '@material-ui/icons/PauseCircleFilled';
import PropTypes from 'prop-types';

class PauseCircleComponent extends React.Component {
  render() {
    return (
      <PauseCircle
        style={this.props.style}
        className={this.props.className}
      />
    )
  }
}

PauseCircleComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
}

export default PauseCircleComponent;
